<template>
	<div class="createIng">
		<div class="chuangjian">
			<div class="lienew">体验版创建工单：</div>
			<div class="souinput">
				<el-select v-model="agentvalue" placeholder="请选择需要创建的工单">
					<el-option v-for="item in agentoptions" :label="item.label" :value="item.label"></el-option>
				</el-select>
			</div>
		</div>
		<div class="chuanjList">
			<Landing ref="landing" v-if="agentvalue == '落地页（体验版）'"></Landing>
			<Program ref="program" v-if="agentvalue == '广告计划（体验版）'"></Program>
			<div class="Listnone" v-if="agentvalue == ''">
				<i class="iconfont icon-zanwushuju"></i>
				<span>暂无数据</span>
			</div>
		</div>
	</div>
</template>

<script>
import Landing from './landing.vue'
import Program from './program.vue'
export default {
	data () {
		return {
			agentvalue:'广告计划（体验版）',
			agentoptions:[
				{
					label: '落地页（体验版）'
				}, {
					label: '广告计划（体验版）'
				}
			],
		}
	},
	components:{
		Landing,
		Program,
	},
	mounted(){

	},

	methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	}
}
</script>

<style lang="scss" scoped>
.createIng{
	.chuangjian{
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 1px solid #ededed;
	}
	.chuanjList{
		padding-top: 20px;
	}
}
@media screen and (max-width: 750px) {
	.createIng{
		.chuangjian{
			padding-bottom: .25rem;
		}
		.chuanjList{
			padding-top: .25rem;
		}
	}
}
</style>
<style lang="scss">
.inputbox .el-upload .el-upload-dragger{
	width: 450px;
}
.chuanjList{
	.titname{
		max-width: 800px;
		text-align: center;
		font-size: 24px;
		margin-bottom: 20px;
		font-weight: 500;
		color: #333;
	}
	
	.tijiaoanniu{
		padding: 20px 0;
		text-align: center;
		.el-button{
			padding: 9px 25px;
		}
	}
}

</style>