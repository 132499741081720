<template>
	<!-- 落地页 -->
	<div class="landing">
		<div class="gongdan">
			<div class="dan">
				<div class="text"><span>*</span>广告账户ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="advertiser_account_id" placeholder="请填写广告账户ID（限1条）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>代理商选择:</div>
				<div class="inputbox">
					<el-select v-model="adv_main_id" @change="onSelect" placeholder="请选择广告代理商">
						<el-option v-for="item in agentoptions" :key="item.adv_main_id" :label="item.advertiser_name" :value="item.adv_main_id" :disabled="!item.status"></el-option>
					</el-select>
				</div>
			</div>
			<!-- <div class="dan">
				<div class="text"><span>*</span>工单标题:</div>
				<div class="inputbox"><el-input v-model="repair_order_title" placeholder="请填写工单标题"></el-input></div>
			</div> -->
			<div class="dan">
				<div class="text"><span>*</span>广告主名称:</div>
				<div class="inputbox"><el-input v-model="advertiser_name" placeholder="请填写广告主名称"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>问题描述:</div>
				<div class="inputbox"><el-input type="textarea" :rows="3" v-model="problem_description" placeholder="请具体描述问题情况"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>落地页ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="landing_page_id" placeholder="请填落地页ID"></el-input></div>
			</div>
			<div class="dan">
				<div class="text">附件:</div>
				<div class="inputbox">
					<el-upload class="upload-demo" drag :action="$api.file_upload + '?token=' + token" :limit="10" 
						ref="upload"
						accept=".jpeg,.png,.doc,.zip"
						show-file-list
						:file-list="fileListUpload"
						:data="uploadData"
						:on-exceed="onExceedtoupload"
						:on-remove="onRemoveupload"
						:on-success="onSuccessupload"
						:before-upload="beforeAvatarUpload"
						:on-change="onChangeUpload">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">文件不大于 20MB，最多可传 10 个，扩展名为：.jpeg，.png，.doc，.zip</div>
					</el-upload>
				</div>
			</div>
		</div>
		<div class="tijiaoanniu">
			<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
		</div>
		<el-dialog title="提示" :visible.sync="SuceeDialogVisible" width="400px">
			<span>提交成功</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="SuceeDialogVisible = false">继续创建工单</el-button>
				<el-button type="primary" @click="toWorkList">返回列表页</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data () {
		return {
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			agentoptions:[
				{
					advertiser_name:"H端口（体验版）",
					adv_main_id:1,
					status:false,
				},{
					advertiser_name:"H端口（体验版）",
					adv_main_id:2,
					status:true,
				},
			],
			adv_main_id:'',//广告代理商
			repair_order_title:'落地页不过',//工单标题
			advertiser_name:'',//广告主名称
			advertiser_account_id:'',//广告账户ID
			problem_description:'',//问题描述
			landing_page_id:'',//落地页ID
			temp_class:'LandingPageTemp',//固定值
			uploadData:{
				file_id:8960,
			},//上传文件时传送的参数
			attachment_id:[],//上传文件列表只取ID
			fileListUpload:[],//上传的文件列表
			delenteAnniu:false,//按钮禁用&&启用
			SuceeDialogVisible:false,
		}
	},

	mounted(){
	},

	methods: {
		//广告主id失去焦点时
		// onAdvertData(){
		// 	var prame = {
		// 		agent_id:this.advertiser_account_id,
		// 	}
		// 	this.$service.get(this.$api.getPortForAdvertiser,prame, (res)=> {
		// 		if(res.code == 200){
		// 			if(res.data.port_id){
		// 				this.adv_main_id = res.data.port_id
		// 				this.$message({message: '已为您自动匹配代理商',type: 'success'})
		// 			}
		// 		}
		// 	})
		// },
		//广告代理商选择时
		onSelect(){
			this.uploadData.adv_main_id = this.adv_main_id
			// this.ongetRepairOrderLog()
		},
		//获取代理商列表
		// ongetAdvertiserList(){
		// 	this.$service.get(this.$api.getAdvertiserList,{}, (res)=> {
		// 		if(res.code == 200){
		// 			this.agentoptions = res.data
		// 		}
		// 	})
		// },
		//获取最近一次提交
		// ongetRepairOrderLog(){
		// 	var prame ={
		// 		ad_main_id:this.adv_main_id,
		// 		temp_class:'LandingPageTemp'
		// 	}
		// 	this.$service.get(this.$api.getRepairOrderLog,prame, (res)=> {
		// 		if(res.code == 200){
		// 			if(res.data != null){
		// 				this.repair_order_title = res.data.content_json.repair_order_title
		// 				this.advertiser_name = res.data.content_json.advertiser_name
		// 				this.advertiser_account_id = res.data.content_json.advertiser_account_id
		// 				this.landing_page_id = res.data.content_json.landing_page_id
		// 			}else{
		// 				this.repair_order_title = '落地页不过'
		// 				this.advertiser_name = ''
		// 				this.advertiser_account_id = ''
		// 				this.landing_page_id = ''
		// 			}
		// 		}
		// 	})
		// },
		// 确认提交
		onSubmit(){
			if(this.adv_main_id == ''){this.$message({message: '请选择广告代理商', type: 'warning'}); return}
			// if(this.repair_order_title == ''){this.$message({message: '请填写工单标题', type: 'warning'}); return}
			if(this.advertiser_name == ''){this.$message({message: '请填写广告主名称', type: 'warning'}); return}
			if(this.advertiser_account_id == ''){this.$message({message: '请填写广告账户ID', type: 'warning'}); return}
			if(this.problem_description == ''){this.$message({message: '请填写问题描述', type: 'warning'}); return}
			if(this.landing_page_id == ''){this.$message({message: '请填写落地页ID', type: 'warning'}); return}
			if(this.delenteAnniu != false){this.$message({message: '请等待文件上传完成后提交', type: 'warning'}); return}
			this.attachment_id = []
			this.fileListUpload.forEach((v,k) => {
				this.attachment_id.push(v.response.data.fileId)
			});
			var prame = {
				adv_main_id:this.adv_main_id,
				repair_order_title:this.repair_order_title,
				advertiser_name:this.advertiser_name,
				advertiser_account_id:this.advertiser_account_id,
				problem_description:this.problem_description,
				landing_page_id:this.landing_page_id,
				attachment_id:this.attachment_id,
				temp_class:this.temp_class
			}
			const fullscreenLoading = this.$loading({
				lock: true,
				text: '提交中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			console.log(prame)
			this.$service.post(this.$api.exp_Repair,prame, (res)=> {
				if(res.code == 200) {
					// this.$message({message: '提交成功',type: 'success'})
					this.adv_main_id = "",//广告代理商
					this.repair_order_title = '落地页不过',//工单标题
					this.advertiser_name = '',//广告主名称
					this.advertiser_account_id = '',//广告账户ID
					this.problem_description = '',//问题描述
					this.landing_page_id = '',//落地页ID
					this.temp_class = 'LandingPageTemp',//固定值
					this.fileListUpload = []//上传文件列表
					this.attachment_id = []//上传文件列表只取ID
					this.$refs.upload.clearFiles()//清除上传的文件
					this.SuceeDialogVisible = true
				}else{
					this.$message({message: res.message, type: 'warning'});
				}
			})
			fullscreenLoading.close()
		},
		//提交成功返回
		toWorkList(){
			this.$router.push('/workList')
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(){
			this.delenteAnniu = false
		},
		//文件上传超出
		onExceedtoupload(){
			this.$message({message: '上传文件已超出10个', type: 'warning'});
		},
		//文件上传成功
		onSuccessupload(res, file,fileList){
			if(res.code == 200){
				this.fileListUpload = fileList
			}else{
				this.$refs.upload.uploadFiles.pop()//上传失败后移出刚上传的文件
				this.$message.error(res.msg);
				console.log(this.$refs.upload.uploadFiles)
			}
			// console.log(res,'上传成功')
			// console.log(fileList,'上传成功')
			// console.log(this.fileListUpload,'文件列表')
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			this.fileListUpload = fileList
			// console.log(file, fileList,'文件移出')
		},
		//上传文件前设置参数
		beforeAvatarUpload(file) {
			this.delenteAnniu = true
			if(this.adv_main_id == ''){
				this.$message.error('请选择广告代理商');
				return false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.landing{
	width: 100%;
}
</style>

